import React, { useState, useContext } from 'react';
import { Window } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Lightbox({ gallery, activeIndex, setIndex, setDisplay }) {
    const { currentBreakpoint } = useContext(Window);
    const [swipeDirection, setSwipeDirection] = useState(null);
    const [startX, setStartX] = useState(0);
    const [endX, setEndX] = useState(0);

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setEndX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (startX - endX > 50) {
            if (activeIndex === gallery.length) {
                setIndex(1);
            } else {
                setIndex(activeIndex + 1);
            }
        } else if (endX - startX > 50) {
            if (activeIndex === 1) {
                setIndex(gallery.length);
            } else {
                setIndex(activeIndex - 1);
            }
        } else {
            setSwipeDirection(null);
        }
    };

    return (
        <div className="lightbox">
            <div className="lightbox__background"
                onClick={() => setDisplay(false)}></div>
            <div className={`lightbox__body ${currentBreakpoint}`}>
                <div className="lightbox__body__header">
                    <div className="lightbox__body__header__pagination">
                        <span className="lightbox__body__header__pagination__active">
                            {activeIndex}
                        </span>
                        /
                        <span className="lightbox__body__header__pagination__total">
                            {gallery.length}
                        </span>
                    </div>
                    <button className="lightbox__body__header__close"
                        onClick={() => setDisplay(false)}>
                        <FontAwesomeIcon icon="fas fa-times" />
                    </button>
                </div>
                <div className="lightbox__body__pictures"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}>
                    {gallery.map((image, index) => (
                        <div key={index}
                            className={`lightbox__body__pictures__picture ${image.index === activeIndex ? "visible" : ""}`}>
                            <img className="lightbox__body__pictures__picture__image image-loading"
                                src={image.src}
                                alt={image.alt}
                                loading="lazy" />
                        </div>
                    ))}
                </div>
                {gallery.length > 1 &&
                    <div className={`lightbox__body__footer ${currentBreakpoint}`}>
                        <button className="lightbox__body__footer__arrow"
                            onClick={() => {
                                if (activeIndex === 1) {
                                    setIndex(gallery.length);
                                } else {
                                    setIndex(activeIndex - 1);
                                }
                            }}>
                            <FontAwesomeIcon icon="fas fa-arrow-left" />
                        </button>
                        <button className="lightbox__body__footer__arrow"
                            onClick={() => {
                                if (activeIndex === gallery.length) {
                                    setIndex(1);
                                } else {
                                    setIndex(activeIndex + 1);
                                }
                            }}>
                            <FontAwesomeIcon icon="fas fa-arrow-right" />
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}