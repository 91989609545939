import React, { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Window, Locale, Title, Content } from '../App';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Wave from '../assets/wave.png';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import CasquettePhoto from '../assets/casquette.jpg';
import BonnetPhoto from '../assets/bonnet.jpg';

export default function Shop() {
    const { currentBreakpoint } = useContext(Window);
    const { locale, translations } = useContext(Locale);
    const { setPage, setAncestors } = useContext(Title);
    const { networks } = useContext(Content);

    const [onPage, setOnPage] = useState(false);

    const products = [
        {
            name: translations[locale].shop.cap,
            price: 15,
            colors: [
                "black",
            ],
            sizes: [
                translations[locale].shop.one_size,
            ],
            photo: CasquettePhoto,
        },
        {
            name: translations[locale].shop.t_shirt,
            price: 25,
            colors: [
                "black",
                "pink",
            ],
            sizes: [
                "S",
                "M",
                "L",
                "XL",
                "XXL",
            ],
            photo: "https://images.unsplash.com/photo-1551799517-eb8f03cb5e6a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            name: translations[locale].shop.tank_top,
            price: 20,
            colors: [
                "black",
                "pink",
            ],
            sizes: [
                "S",
                "M",
                "L",
                "XL",
                "XXL",
            ],
            photo: "https://images.unsplash.com/photo-1571666274590-f8cc87006500?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
        },
        {
            name: translations[locale].shop.sweatshirt,
            price: 50,
            colors: [
                "black",
                "pink",
            ],
            sizes: [
                "S",
                "M",
                "L",
                "XL",
                "XXL",
            ],
            photo: "https://bracson.b-cdn.net/Shop/sweat.png",
        },
        {
            name: translations[locale].shop.cap_2,
            price: 20,
            colors: [
                "pink",
            ],
            sizes: [
                translations[locale].shop.one_size,
            ],
            photo: BonnetPhoto,
        },
        {
            name: translations[locale].shop.fan,
            price: 5,
            colors: [
                "pink",
            ],
            sizes: [
                translations[locale].shop.one_size,
            ],
            photo: "https://bracson.b-cdn.net/Shop/b9292da0-e688-4146-b446-11ffbe2925b4-min.webp",
        },
        {
            name: translations[locale].shop.cup,
            price: 3,
            colors: [
                "pink",
            ],
            sizes: [
                translations[locale].shop.one_size,
            ],
            photo: "https://bracson.b-cdn.net/Shop/gobelet.png",
        },
    ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        setOnPage(true);
        setPage("shop");
        setAncestors([
            { name: translations[locale].header.shop, url: "/shop" },
        ]);
    }, []);

    return (
        <React.Fragment>
            <HelmetProvider>
                <Helmet>
                    <title>
                        Bracson | {translations[locale].header.shop}
                    </title>
                    <meta name="description"
                        content={translations[locale].shop.meta_description} />
                    <meta name="og:description"
                        content={translations[locale].shop.meta_description} />
                </Helmet>
            </HelmetProvider>
            <div className={`home init ${onPage ? "init--achieved" : ""}`}>
                <div className="home__picture">
                    <img className="home__picture__image home__picture__image--shop home__picture__image--page image-loading"
                        src="https://bracson.b-cdn.net/Shop/762497f6-9cbe-4010-b45b-3a5fa415dd38-min.webp"
                        alt={translations[locale].header.shop} />
                    <div className={`home__picture__wave ${currentBreakpoint}`}>
                        <img className="home__picture__wave__image"
                            src={Wave}
                            alt="Wave" />
                    </div>
                </div>
                <div className="container">
                    <div className={`home__content home__content--service container__content home__content--page ${currentBreakpoint}`}>
                        <div className="home__content__block">
                            <h1 className={`home__content__title ${currentBreakpoint}`}>
                                {translations[locale].header.shop}
                            </h1>
                            <p className="home__content__block__text"
                                dangerouslySetInnerHTML={{ __html: translations[locale].shop.text }}>
                            </p>
                            <div className="home__content__block__links">
                                <Link className="button button--arrow"
                                    to="/contact">
                                    <div className="button--arrow__icon">
                                        <FontAwesomeIcon icon="fa-solid fa-arrow-right" />
                                    </div>
                                    {translations[locale].header.contact}
                                </Link>
                                {networks.map((network, index) => (
                                    <a key={index}
                                        className="button button--more"
                                        href={network.url}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={network.icon} />
                                        {network.title}
                                    </a>
                                ))}
                            </div>
                        </div>
                        <div className="home__content__block">
                            <h2 className={`home__content__block__title ${currentBreakpoint}`}>
                                {translations[locale].shop.products}
                            </h2>
                            <div className={`bicyclette__gallery bicyclette__gallery--shop ${currentBreakpoint}`}>
                                {!["xl", "xxl"].includes(currentBreakpoint) ?
                                    <React.Fragment>
                                        <div className={`bicyclette__gallery__list bicyclette__gallery__list--shop ${currentBreakpoint}`}>
                                            {products.slice(0, Math.ceil(products.length / 2)).map((product, index) => (
                                                <div key={index}
                                                    className="shop__product">
                                                    <div className={`shop__product__image ${currentBreakpoint}`}>
                                                        <img key={index}
                                                            className="shop__product__image__img image-loading"
                                                            src={product.photo}
                                                            alt={product.title}
                                                            loading="lazy" />
                                                    </div>
                                                    <div className={`shop__product__bottom ${currentBreakpoint}`}>
                                                        <div className="shop__product__first-line">
                                                            <h3 className="shop__product__name">
                                                                {product.name}
                                                            </h3>
                                                            <span className="shop__product__price">
                                                                {product.price} €
                                                            </span>
                                                        </div>
                                                        <div className="shop__product__second-line">
                                                            <div className="shop__product__colors">
                                                                {product.colors.map((color, index) => (
                                                                    <div key={index}
                                                                        className={`shop__product__colors__item ${color}`}></div>
                                                                ))}
                                                            </div>
                                                            <div className="shop__product__sizes">
                                                                {product.sizes.map((size, index) => (
                                                                    <span key={index}
                                                                        className="shop__product__sizes__item">
                                                                        {size}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={`bicyclette__gallery__list bicyclette__gallery__list--shop ${currentBreakpoint}`}>
                                            {products.slice(Math.ceil(products.length / 2), products.length).map((product, index) => (
                                                <div key={index}
                                                    className="shop__product">
                                                    <div className={`shop__product__image ${currentBreakpoint}`}>
                                                        <img key={index}
                                                            className="shop__product__image__img image-loading"
                                                            src={product.photo}
                                                            alt={product.title}
                                                            loading="lazy" />
                                                    </div>
                                                    <div className={`shop__product__bottom ${currentBreakpoint}`}>
                                                        <div className="shop__product__first-line">
                                                            <h3 className="shop__product__name">
                                                                {product.name}
                                                            </h3>
                                                            <span className="shop__product__price">
                                                                {product.price} €
                                                            </span>
                                                        </div>
                                                        <div className="shop__product__second-line">
                                                            <div className="shop__product__colors">
                                                                {product.colors.map((color, index) => (
                                                                    <div key={index}
                                                                        className={`shop__product__colors__item ${color}`}></div>
                                                                ))}
                                                            </div>
                                                            <div className="shop__product__sizes">
                                                                {product.sizes.map((size, index) => (
                                                                    <span key={index}
                                                                        className="shop__product__sizes__item">
                                                                        {size}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                    : <React.Fragment>
                                        <div className={`bicyclette__gallery__list bicyclette__gallery__list--shop ${currentBreakpoint}`}>
                                            {products.slice(0, Math.ceil(products.length / 3)).map((product, index) => (
                                                <div key={index}
                                                    className="shop__product">
                                                    <div className={`shop__product__image ${currentBreakpoint}`}>
                                                        <img key={index}
                                                            className="shop__product__image__img image-loading"
                                                            src={product.photo}
                                                            alt={product.title}
                                                            loading="lazy" />
                                                    </div>
                                                    <div className={`shop__product__bottom ${currentBreakpoint}`}>
                                                        <div className="shop__product__first-line">
                                                            <h3 className="shop__product__name">
                                                                {product.name}
                                                            </h3>
                                                            <span className="shop__product__price">
                                                                {product.price} €
                                                            </span>
                                                        </div>
                                                        <div className="shop__product__second-line">
                                                            <div className="shop__product__colors">
                                                                {product.colors.map((color, index) => (
                                                                    <div key={index}
                                                                        className={`shop__product__colors__item ${color}`}></div>
                                                                ))}
                                                            </div>
                                                            <div className="shop__product__sizes">
                                                                {product.sizes.map((size, index) => (
                                                                    <span key={index}
                                                                        className="shop__product__sizes__item">
                                                                        {size}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={`bicyclette__gallery__list bicyclette__gallery__list--shop ${currentBreakpoint}`}>
                                            {products.slice(Math.ceil(products.length / 3), (products.length - Math.floor(products.length / 3))).map((product, index) => (
                                                <div key={index}
                                                    className="shop__product">
                                                    <div className={`shop__product__image ${currentBreakpoint}`}>
                                                        <img key={index}
                                                            className="shop__product__image__img image-loading"
                                                            src={product.photo}
                                                            alt={product.title}
                                                            loading="lazy" />
                                                    </div>
                                                    <div className={`shop__product__bottom ${currentBreakpoint}`}>
                                                        <div className="shop__product__first-line">
                                                            <h3 className="shop__product__name">
                                                                {product.name}
                                                            </h3>
                                                            <span className="shop__product__price">
                                                                {product.price} €
                                                            </span>
                                                        </div>
                                                        <div className="shop__product__second-line">
                                                            <div className="shop__product__colors">
                                                                {product.colors.map((color, index) => (
                                                                    <div key={index}
                                                                        className={`shop__product__colors__item ${color}`}></div>
                                                                ))}
                                                            </div>
                                                            <div className="shop__product__sizes">
                                                                {product.sizes.map((size, index) => (
                                                                    <span key={index}
                                                                        className="shop__product__sizes__item">
                                                                        {size}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        <div className={`bicyclette__gallery__list bicyclette__gallery__list--shop ${currentBreakpoint}`}>
                                            {products.slice((products.length - Math.floor(products.length / 3)), products.length).map((product, index) => (
                                                <div key={index}
                                                    className="shop__product">
                                                    <div className={`shop__product__image ${currentBreakpoint}`}>
                                                        <img key={index}
                                                            className="shop__product__image__img image-loading"
                                                            src={product.photo}
                                                            alt={product.title}
                                                            loading="lazy" />
                                                    </div>
                                                    <div className={`shop__product__bottom ${currentBreakpoint}`}>
                                                        <div className="shop__product__first-line">
                                                            <h3 className="shop__product__name">
                                                                {product.name}
                                                            </h3>
                                                            <span className="shop__product__price">
                                                                {product.price} €
                                                            </span>
                                                        </div>
                                                        <div className="shop__product__second-line">
                                                            <div className="shop__product__colors">
                                                                {product.colors.map((color, index) => (
                                                                    <div key={index}
                                                                        className={`shop__product__colors__item ${color}`}></div>
                                                                ))}
                                                            </div>
                                                            <div className="shop__product__sizes">
                                                                {product.sizes.map((size, index) => (
                                                                    <span key={index}
                                                                        className="shop__product__sizes__item">
                                                                        {size}
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}